/**
 * Copyright 2024 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import type {
  FormattedContentStackLandingPage,
  ContentStackPdpSlot,
  SizePerDevice,
} from '@/features/contentstack/interface';
import { CMSFaq } from '@/features/contentstack/components/CMSFaq/CMSFaq';
import { CMSBannerSplit } from '@/features/contentstack/components/CMSBannerSplit/CMSBannerSplit';
import {
  CMSSpacer,
  type CMSSpacerProps,
} from '@/features/contentstack/components/CMSSpacer/CMSSpacer';
import { CMSMenuList } from '@/features/contentstack/components/CMSMenuList/CMSMenuList';
import { CMSYmme } from '@/features/contentstack/components/CMSYmme/CMSYmme';
import { CMSImageCategory } from '@/features/contentstack/components/CMSImageCategory/CMSImageCategory';
import { CMSBannerCTA } from '@/features/contentstack/components/CMSBannerCTA/CMSBannerCTA';
import { CMSLogoDescription } from '@/features/contentstack/components/CMSLogoDescription/CMSLogoDescription';
import { CMSCategoryGrid } from '@/features/contentstack/components/CMSCategoryGrid/CMSCategoryGrid';
import {
  CMSImageContent,
  isValidCMSImageContent,
} from '@/features/contentstack/components/CMSImageContent/CMSImageContent';
import { CMSImageContentBackground } from '@/features/contentstack/components/CMSImageContentBackground/CMSImageContentBackground';
import { CMSGrid } from '@/features/contentstack/components/CMSGrid/CMSGrid';
import { CMSPreFooter } from '@/features/contentstack/components/CMSPreFooter/CMSPreFooter';
import { CMSBlogGrid } from '@/features/contentstack/components/CMSBlogGrid/CMSBlogGrid';
import { CMSProductTypeGrid } from '@/features/contentstack/components/CMSProductTypeGrid/CMSProductTypeGrid';
import { CMSRichText } from '@/features/contentstack/components/CMSRichText/CMSRichText';
import { CMSFeaturedContent } from '@/features/contentstack/components/CMSFeaturedContent/CMSFeaturedContent';
import { CMSVideoContent } from '@/features/contentstack/components/CMSVideoContent/CMSVideoContent';
import { CMSProductChart } from '@/features/contentstack/components/CMSProductChart/CMSProductChart';
import { CMSBanner } from '@/features/contentstack/components/CMSBanner/CMSBanner';
import { CMSButtonGrid } from '@/features/contentstack/components/CMSButtonGrid/CMSButtonGrid';
import { CMSTarget } from '@/features/contentstack/components/CMSTarget/CMSTarget';
import { CMSExperienceInjector } from '@/features/contentstack/components/CMSExperienceInjector/CMSExperienceInjector';
import { CMSImageVideoSplit } from '@/features/contentstack/components/CMSImageVideoSplit/CMSImageVideoSplit';
import { CMSHeading } from '@/features/contentstack/components/CMSHeading/CMSHeading';
import { CMSIconGrid } from '@/features/contentstack/components/CMSIconGrid/CMSIconGrid';
import { CMSCertona } from '@/features/contentstack/components/CMSCertona/CMSCertona';
import { STARCTypography } from '@/components/STARCTypography';
import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';
import { routePaths } from '@/constants/routePaths';
import { type RewardsActivityFilters } from '@/features/rewards';
import { RewardsActivity } from '@/features/rewards/components/RewardsActivity/RewardsActivity';
import { useRewardDetails } from '@/features/rewards/api/getRewardDetails';
import { useRewardsActivity } from '@/features/rewards/api/getRewardsActivity';
import type { ReduxState } from '@/types';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useDispatch } from '@/hooks/redux/useDispatch';
import { useScrollIntoViewDOM } from '@/hooks/useScrollIntoView';
import { SuperDuperHero } from '@/features/home/components/SuperDuperHero/SuperDuperHero';
import { type CertonaBlockProps, type CertonaRecommendation } from '@/features/certona';
import { CMSGridBanner } from '@/features/contentstack/components/CMSGridBanner/CMSGridBanner';
import CMSTermsAndConditions from '@/features/contentstack/components/CMSTermsAndConditions/CMSTermsAndConditions';

const LazyCMSCircularPDF = dynamic(
  () =>
    import('@/features/contentstack/components/CMSCircularPDF/CMSCircularPDF').then(
      (mod) => mod.CMSCircularPDF
    ),
  {
    ssr: false,
  }
);

const LazyMembershipStatusBlock = dynamic(() =>
  import('@/components/ContentBlocks/MembershipStatusBlock').then(
    (mod) => mod.MembershipStatusBlock
  )
);

const rewardsLoggedInSizes: SizePerDevice = {
  mobile: {
    width: 704,
    height: 1089,
  },
  tablet: {
    width: 2200,
    height: 764,
  },
  desktop: {
    width: 3120,
    height: 773,
  },
};

const CMSLPSpacer = ({ children, ...rest }: { id?: string } & CMSSpacerProps) => {
  const router = useRouter();
  const isNotPDP = router.route !== '/products/[...slug]';
  return (
    <CMSSpacer landingPage={isNotPDP} {...rest}>
      {children}
    </CMSSpacer>
  );
};

type Props = {
  certonaRecommendations?: CertonaRecommendation[];
  content:
    | FormattedContentStackLandingPage['modularBlocks']
    | ContentStackPdpSlot['pdp_slot_content'];
  isTopContent?: boolean;
  setCertonaRecommendationAddedToCart?: React.Dispatch<React.SetStateAction<boolean>>;
} & CertonaBlockProps;

export const CMSModularHandler = ({
  content,
  certonaRecommendations,
  setCertonaRecommendationAddedToCart,
  isTopContent,
  ...certonaProps
}: Props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [filters, setFilters] = useState<RewardsActivityFilters | undefined>();
  const { data: rewardsDetails } = useRewardDetails();
  const { data: rewardsData } = useRewardsActivity(filters);
  const isLoggedIn = useSelector(({ appData }: ReduxState) => appData.userAuthenticated);

  useScrollIntoViewDOM([router.asPath, content]);

  useEffect(() => {
    if (
      isLoggedIn &&
      (router.asPath === routePaths.rewardsLandingPage ||
        router.asPath.includes(`${routePaths.rewardsLandingPage}?`))
    ) {
      const toDate = dayjs();
      const fromDate = toDate.subtract(1, 'year');
      const dateFormat = 'YYYYMMDD';

      const toDateFormatted = dayjs(toDate).format(dateFormat);
      const fromDateFormatted = dayjs(fromDate).format(dateFormat);

      setFilters({
        fromDate: fromDateFormatted,
        toDate: toDateFormatted,
        orderType: null,
      });
    }
  }, [dispatch, isLoggedIn, router.asPath]);

  return (
    <STARCTypography>
      {content?.map((modularBlock, i) => {
        if ('faq' in modularBlock && modularBlock.faq?.faq?.[0]?.faq?.length) {
          return (
            <CMSLPSpacer key={i}>
              <CMSFaq content={modularBlock.faq.faq[0]} />
            </CMSLPSpacer>
          );
        }
        if (modularBlock.image_category_grid?.image_category_grid?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSImageCategory content={modularBlock.image_category_grid.image_category_grid[0]} />
            </CMSLPSpacer>
          );
        }

        if (modularBlock.banner?.banner?.[0]?.asset_size) {
          return (
            <CMSLPSpacer key={i} id={modularBlock.banner.banner[0].component_id ?? ''}>
              <CMSBanner
                content={modularBlock.banner.banner[0]}
                priority={isTopContent && i === 0}
                sizePerDevice={rewardsLoggedInSizes}
              />
            </CMSLPSpacer>
          );
        }

        if (modularBlock.banner_split?.banner_split?.[0]) {
          return (
            <CMSLPSpacer key={i} id={modularBlock.banner_split.banner_split[0].component_id ?? ''}>
              <CMSBannerSplit content={modularBlock.banner_split.banner_split[0]} />
            </CMSLPSpacer>
          );
        }

        if ('ymme' in modularBlock && modularBlock.ymme?.ymme?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSYmme content={modularBlock.ymme.ymme[0]} nonShopPage={true} />
            </CMSLPSpacer>
          );
        }

        if (modularBlock.image_content_background?.image_content_background?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSImageContentBackground
                content={modularBlock.image_content_background.image_content_background[0]}
              />
            </CMSLPSpacer>
          );
        }
        if (modularBlock.logo_description?.logo_description?.[0]) {
          return (
            <CMSLPSpacer
              key={i}
              id={modularBlock.logo_description.logo_description[0].component_id ?? ''}
            >
              <CMSLogoDescription content={modularBlock.logo_description.logo_description[0]} />
            </CMSLPSpacer>
          );
        }

        if (modularBlock.banner_cta?.banner_cta?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSBannerCTA
                content={modularBlock.banner_cta.banner_cta[0]}
                priority={isTopContent && i === 0}
              />
            </CMSLPSpacer>
          );
        }

        if ('menu_list' in modularBlock && modularBlock.menu_list?.menu_list?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSMenuList content={modularBlock.menu_list.menu_list[0]} />
            </CMSLPSpacer>
          );
        }

        if (
          'super_duper_hero' in modularBlock &&
          modularBlock.super_duper_hero?.super_duper_hero?.[0]
        ) {
          return (
            <CMSLPSpacer key={`${i}-hero`} removeHorizontalSpacing>
              <SuperDuperHero options={modularBlock.super_duper_hero.super_duper_hero[0]} />
            </CMSLPSpacer>
          );
        }

        if ('target_div' in modularBlock && modularBlock.target_div?.target_div?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSTarget content={modularBlock.target_div.target_div[0]} />
            </CMSLPSpacer>
          );
        }

        if ('heading' in modularBlock && modularBlock.heading?.heading?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSHeading key={i} content={modularBlock.heading.heading[0]} />
            </CMSLPSpacer>
          );
        }

        if (
          'experience_injector' in modularBlock &&
          modularBlock.experience_injector?.experience_injector?.[0]
        ) {
          return (
            <CMSLPSpacer key={i}>
              <CMSExperienceInjector
                content={modularBlock.experience_injector.experience_injector[0]}
              />
            </CMSLPSpacer>
          );
        }

        if (
          'image_video_split' in modularBlock &&
          modularBlock.image_video_split?.image_video_split?.[0]
        ) {
          return (
            <CMSLPSpacer key={i}>
              <CMSImageVideoSplit content={modularBlock.image_video_split.image_video_split[0]} />
            </CMSLPSpacer>
          );
        }

        if ('grid_banner' in modularBlock && modularBlock.grid_banner?.grid_banner?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSGridBanner content={modularBlock.grid_banner.grid_banner[0]} />
            </CMSLPSpacer>
          );
        }

        if (modularBlock.category_grid?.category_grid?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSCategoryGrid content={modularBlock.category_grid.category_grid[0]} />
            </CMSLPSpacer>
          );
        }

        if (
          modularBlock.image_content?.image_content?.[0] &&
          isValidCMSImageContent(modularBlock.image_content?.image_content?.[0])
        ) {
          return (
            <CMSLPSpacer key={i}>
              <CMSImageContent content={modularBlock.image_content.image_content[0]} />
            </CMSLPSpacer>
          );
        }

        if (modularBlock.button_grid?.button_grid?.[0]) {
          return (
            <CMSLPSpacer key={i} removeHorizontalSpacing>
              <CMSButtonGrid content={modularBlock.button_grid.button_grid[0]} />
            </CMSLPSpacer>
          );
        }

        if (modularBlock.parttype_grid?.parttype_grid?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSGrid content={modularBlock.parttype_grid.parttype_grid[0]} />
            </CMSLPSpacer>
          );
        }

        if ('prefooter' in modularBlock && modularBlock.prefooter?.prefooter?.[0]) {
          return (
            <CMSLPSpacer key={`${i}-prefooter`}>
              <CMSPreFooter content={modularBlock.prefooter.prefooter[0]?.column} />
            </CMSLPSpacer>
          );
        }
        if (modularBlock.product_grid?.product_grid?.[0]) {
          return (
            <CMSLPSpacer key={`${i}-product_grid`}>
              <CMSProductTypeGrid
                content={modularBlock.product_grid.product_grid[0]}
                gridIndex={i}
              />
            </CMSLPSpacer>
          );
        }

        if (modularBlock.blogtype_grid?.blogtype_grid?.[0]) {
          return (
            <CMSLPSpacer
              key={i}
              id={modularBlock.blogtype_grid.blogtype_grid[0].component_id ?? ''}
            >
              <CMSBlogGrid content={modularBlock.blogtype_grid.blogtype_grid[0]} />
            </CMSLPSpacer>
          );
        }

        if (modularBlock.rich_text_editor?.rich_text_editor?.[0]) {
          const contentBackground =
            modularBlock.rich_text_editor?.rich_text_editor?.[0].description_background;
          const contentHasBackground = !!contentBackground && contentBackground !== 'None';

          return (
            <CMSLPSpacer
              key={`${i}-rich-text`}
              removeHorizontalSpacing={contentHasBackground}
              id={modularBlock.rich_text_editor.rich_text_editor[0].component_id ?? ''}
            >
              <CMSRichText content={modularBlock.rich_text_editor.rich_text_editor[0]} />
            </CMSLPSpacer>
          );
        }
        if (modularBlock.product_chart?.product_chart?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSProductChart content={modularBlock.product_chart.product_chart[0]} />
            </CMSLPSpacer>
          );
        }

        if (modularBlock.featured_content?.featured_content?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSFeaturedContent content={modularBlock.featured_content.featured_content[0]} />
            </CMSLPSpacer>
          );
        }
        if (modularBlock.video_content?.video_content?.[0]) {
          return (
            <CMSLPSpacer key={`${i}-rich-text`}>
              <CMSVideoContent content={modularBlock.video_content.video_content[0]} />
            </CMSLPSpacer>
          );
        }

        if ('membership_status' in modularBlock && modularBlock.membership_status?.enable) {
          if (isLoggedIn && rewardsDetails?.loyaltyCardNumber) {
            return (
              <CMSLPSpacer key={`${i}-membership`}>
                <LazyMembershipStatusBlock isFromCMS />
              </CMSLPSpacer>
            );
          }
        }

        if ('rewards_activity' in modularBlock && modularBlock.rewards_activity?.enable) {
          if (
            isLoggedIn &&
            rewardsDetails?.loyaltyCardNumber &&
            rewardsData &&
            rewardsData.rewardsActivityLineItems.length > 0
          ) {
            return (
              <CMSLPSpacer key={`${i}-rewards-activity`}>
                <RewardsActivity />
              </CMSLPSpacer>
            );
          }
        }

        if ('icon_grid' in modularBlock && modularBlock.icon_grid?.icon_grid?.[0]) {
          return (
            <CMSLPSpacer key={`${i}-icon-grid`}>
              <CMSIconGrid content={modularBlock.icon_grid.icon_grid?.[0]} />
            </CMSLPSpacer>
          );
        }

        if ('circular_pdf' in modularBlock && modularBlock.circular_pdf?.circular_pdf?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <LazyCMSCircularPDF content={modularBlock.circular_pdf.circular_pdf[0]} />
            </CMSLPSpacer>
          );
        }

        if ('certona' in modularBlock && modularBlock.certona?.certona?.[0]) {
          return (
            <CMSLPSpacer key={i}>
              <CMSCertona
                content={modularBlock.certona.certona[0]}
                certonaRecommendations={certonaRecommendations}
                onCertonaAddToCartClick={() => {
                  setCertonaRecommendationAddedToCart?.(true);
                }}
                {...certonaProps}
              />
            </CMSLPSpacer>
          );
        }

        if (
          'terms_conditions' in modularBlock &&
          modularBlock.terms_conditions?.terms_conditions?.[0]
        ) {
          return (
            <CMSLPSpacer key={i}>
              <CMSTermsAndConditions
                content={modularBlock.terms_conditions?.terms_conditions?.[0]}
              />
            </CMSLPSpacer>
          );
        }
        return null;
      })}
    </STARCTypography>
  );
};
